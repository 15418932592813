import React, { useState } from 'react';
import {
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button
} from '@material-ui/core';
import { Link } from 'react-router-dom';

const Leaderboard = ({ people, teams }) => {
  const [sortOrder, setSortOrder] = useState('desc');
 // Example trades data, replace with actual data source

  const handleSortToggle = () => {
    setSortOrder(sortOrder === 'desc' ? 'asc' : 'desc');
  };

  const sortedPeople = [...people].sort((a, b) => {
    return sortOrder === 'desc' ? b.percentage - a.percentage : a.percentage - b.percentage;
  });

  const renderPercentage = (teamName) => {
    const percentage = (teams[teamName] || 0).toFixed(1);
    
    let percentageStyle = {};
    if (percentage === '0.0') {
      percentageStyle = {
        color: 'red',
        textDecoration: 'line-through'
      };
    } else {
      percentageStyle = {
        color: 'orange',
        textDecoration: 'underline'
      };
    }
  
    return (
      <span style={percentageStyle}>
        {percentage}%
      </span>
    );
  };
  
  const renderTeamName = (teamName) => {
    const percentage = (teams[teamName] || 0).toFixed(1);
  
    if (percentage === '0.0') {
      return (
        <span style={{ color: 'red', textDecoration: 'line-through' }}>
          {teamName}
        </span>
      );
    }
    if (teamName.includes('[Dropped]')) {
      return (
        <span style={{ color: 'purple', textDecoration: 'line-through' }}>
          {teamName}
        </span>
      );
    }
    if (teamName.includes('[OUT]')) {
      return (
        <span style={{ color: 'red', textDecoration: 'line-through' }}>
          {teamName}
        </span>
      );
    }
    return teamName;
  };
  
  


  return (
    <TableContainer component={Paper}>
      <Button component={Link} to="/trades" color="primary" variant="contained">
        Trades
      </Button>
      <Button onClick={handleSortToggle}>
        Sort by Percentage ({sortOrder === 'desc' ? 'Highest to Lowest' : 'Lowest to Highest'})
      </Button>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>#</TableCell>
            <TableCell>Name</TableCell>
            <TableCell>Tier 3</TableCell>
            <TableCell>Tier 2</TableCell>
            <TableCell>Tier 1</TableCell>
            <TableCell>Extra Teams</TableCell>
            <TableCell>Percentage</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {sortedPeople.map((person, index) => (
            <TableRow key={person.name}>
              <TableCell>{index + 1}</TableCell>
              <TableCell>{person.name}</TableCell>
              <TableCell>{renderTeamName(person.tier3)} {renderPercentage(person.tier3)}</TableCell>
              <TableCell>{renderTeamName(person.tier2)} {renderPercentage(person.tier2)}</TableCell>
              <TableCell>{renderTeamName(person.tier1)} {renderPercentage(person.tier1)}</TableCell>
              <TableCell>
                {person.extraTeams.length > 0 ? (
                  <div>
                    {person.extraTeams.map((team, idx) => (
                      <div key={idx}>
                        {team} {renderPercentage(team)}
                      </div>
                    ))}
                  </div>
                ) : (
                  'None'
                )}
              </TableCell>
              <TableCell>{(person.percentage || 0).toFixed(1)}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default Leaderboard;
