import React from 'react';
import { Link } from 'react-router-dom';
import {
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button
} from '@material-ui/core';

const TradesPage = ({ trades }) => {
  return (
    <div>
      <Button component={Link} to="/leaderboard" color="primary" variant="contained" style={{ marginBottom: '20px' }}>
        Back to Leaderboard
      </Button>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>#</TableCell>
              <TableCell>Seller</TableCell>
              <TableCell>Buyer</TableCell>
              <TableCell>Country</TableCell>
              <TableCell>Price</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {trades.map((trade, index) => (
              <TableRow key={index}>
                <TableCell>{index + 1}</TableCell>
                <TableCell>{trade.seller}</TableCell>
                <TableCell>{trade.buyer}</TableCell>
                <TableCell>{trade.country}</TableCell>
                <TableCell>{trade.price}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default TradesPage;
