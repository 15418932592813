import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { Container } from '@material-ui/core';
import Leaderboard from './components/Leaderboard';
import TradesPage from './components/TradesPage';
import HomePage from './components/HomePage';

const App = () => {
  const [teams, setTeams] = useState({});
  const [people, setPeople] = useState([]);
  const [trades, setTrades] = useState([]);

  useEffect(() => {
    const fetchTeams = async () => {
      try {
        const response = await fetch('/teams.json'); // Adjust the path as necessary
        const data = await response.json();
        setTeams(data);
      } catch (error) {
        console.error('Error fetching teams:', error);
      }
    };

    const fetchPeople = async () => {
      try {
        const response = await fetch('/people.json'); // Adjust the path as necessary
        const data = await response.json();
        setPeople(data);
      } catch (error) {
        console.error('Error fetching people:', error);
      }
    };

    const fetchTrades = async () => {
      try {
        // Simulate trades data for example
        const data = [
          { seller: 'King Kush', buyer: 'Klean Klas', country: 'Tyrkia', price: '8 NOK' },
          { seller: 'King Kush', buyer: 'Klean Klas', country: 'Østerrike', price: '2 NOK' },
          { seller: 'Klean Klas', buyer: 'Magical Michael', country: 'Østerrike', price: '15 NOK' }
        ];
        setTrades(data);
      } catch (error) {
        console.error('Error fetching trades:', error);
      }
    };

    fetchTeams();
    fetchPeople();
    fetchTrades(); // Fetch trades data when component mounts
  }, []);

  useEffect(() => {
    if (people.length > 0 && Object.keys(teams).length > 0) {
      const updatedPeople = people.map(person => {
        const tier1Percentage = teams[person.tier1] || 0;
        const tier2Percentage = teams[person.tier2] || 0;
        const tier3Percentage = teams[person.tier3] || 0;
        const extraTeamsPercentage = (person.extraTeams || []).reduce((acc, team) => acc + (teams[team] || 0), 0);
        const totalPercentage = tier1Percentage + tier2Percentage + tier3Percentage + extraTeamsPercentage;
        return { ...person, percentage: totalPercentage };
      });
      setPeople(updatedPeople);
    }
  }, [teams, people]);

  return (
    <Router>
      <Container>
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/leaderboard" element={<Leaderboard people={people} teams={teams} />} />
          {/* Pass trades data to TradesPage */}
          <Route path="/trades" element={<TradesPage trades={trades} />} />
        </Routes>
      </Container>
    </Router>
  );
};

export default App;
