import React from 'react';
import { Link } from 'react-router-dom';
import { Button, Typography, Grid, Box } from '@material-ui/core';

const HomePage = () => {
  return (
    <Grid
      container
      direction="column"
      justifyContent="center"
      alignItems="center"
      style={{ minHeight: '100vh' }}
    >
      <Box textAlign="center" p={4} style={{ maxWidth: '600px' }}>
        {/* First image */}
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <img src="/UEFA.png" alt="UEFA Logo" style={{ width: '30%' }} />
        </div>
        {/* Two images side by side */}
        <Grid container spacing={3} style={{ marginTop: '20px' }}>
          <Grid item xs={6} style={{ display: 'flex', alignItems: 'center' }}>
            <img src="/nora.png" alt="NORA.ai Logo" style={{ width: '100%' }} />
          </Grid>
          <Grid item xs={6} style={{ display: 'flex', alignItems: 'center' }}>
            <img src="/dscience.png" alt="DScience Logo" style={{ width: '100%' }} />
          </Grid>
        </Grid>

        {/* Title and buttons */}
        <Typography variant="h4" gutterBottom style={{ marginTop: '20px' }}>
          NORA.ai and DScience European Football Championship Fantasy Teams
        </Typography>
        <Box mt={4} style={{ display: 'flex', justifyContent: 'center' }}>
          <Button component={Link} to="/leaderboard" variant="contained" color="primary" style={{ marginRight: '20px' }}>
            Leaderboard
          </Button>
          <Button component={Link} to="/trades" variant="contained" color="primary" style={{marginRight: '20px'}}>
            Trades
          </Button>
          <Button
            variant="contained"
            color="secondary"
            href="https://em.nr.no/method/"  // Replace with your desired URL
            target="_blank"
            rel="noopener noreferrer"
          >
            DataSource
          </Button>
        </Box>

    
      </Box>
    </Grid>
  );
};

export default HomePage;
